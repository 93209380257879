export const constants = {

    // Super Funder
    superFunder: 'superFunder', // Multi Family
    superFunderWithHO: 'superFunderWithHO', // Multi Family & Home Ownership
    // Funder with OA
    funderWithOA: 'funderWithOA', // Multi Family
    funderWithOAandHO: 'funderWithOAandHO', // Home Ownership
    funderWithOAandBoth: 'funderWithOAandBoth', // Multi family & Home Ownership
    // Funder without OA
    funderWithoutOA: 'funderWithoutOA', // Multi Family
    funderWithoutOAandHO: 'funderWithoutOAandHO', // Home Ownership
    funderWithoutOAandBoth: 'funderWithoutOAandBoth', // Multi family & Home Ownership
    // Read Only Funder
    funderWithoutOAandROU: 'funderWithoutOAandROU',
    // Read Only Funder with OA
    readOnlyFunderWithOA: 'readOnlyFunderWithOA', // Multi Family
    readOnlyFunderWithOAandHO: 'readOnlyFunderWithOAwithHO', // Home Ownership
    readOnlyFunderWithOAandBoth: 'readOnlyFunderWithOAandBoth', // Multi family & Home Ownership
    // Read Only Funder without OA
    readOnlyFunderWithoutOA: 'readOnlyFunderWithoutOA', // Multi Family
    readOnlyFunderWithoutOAandHO: 'readOnlyFunderWithoutOAandHO', // Home Ownership
    readOnlyFunderWithoutOAandBoth: 'readOnlyFunderWithoutOAandBoth', // Multi family & Home Ownership
    // Non-Funder with OA
    nonFunderWithOA: 'nonFunderWithOA', // Multi Family
    nonFunderWithOAandHO: 'nonFunderWithOAandHO', // Home Ownership
    nonFunderWithOAandBoth: 'nonFunderWithOAandBoth', // Multi Family & Home Ownership
    // Non-Funder without OA
    nonFunderWithoutOA: 'nonFunderWithoutOA', // Multi Family
    nonFunderWithoutOAandHO: 'nonFunderWithoutOAandHO', // Home Ownership
    nonFunderWithoutOAandBoth: 'nonFunderWithoutOAandBoth', // Multi Family & Home Ownership
    // Onsite
    onsite: 'onsite',
    funder: 'Funder',
}

export const constantColors = [
    '', // no Color
    'validation_green',  //Green classname
    'validation_yellow',  //Yellow classname
    'validation_red',  //Red classname
    'validation_red', //Red classname
    'validation_navyBlue'
];

export const reportLevelConstants = {
    1: 'Onsite Manager',
    2: 'Property Manager',
    3: 'Contractor / Owner',
    4: 'Funder'
};

export const reportActivityConstants = {
    1: 'Preparer',
    2: 'Contractor',
    3: 'Funder'
};

export const contractActivityContants = {
    ethincities: [
        { key:"Hispanic or Latino", value: 1, label:"Hispanic or Latino" },
        { key:"Non-Hispanic or Latino", value: 2, label:"Non-Hispanic or Latino" },
        { key:"Not Disclosed", value: 3, label:"Not Disclosed" }
    ],
    htfLoansSatisfied: [
        { key:"Yes", value: 1, label:"Yes" },
        { key:"No", value: 2, label:"No" },
        { key:"Not Applicable – CLT", value: 3, label:"Not Applicable – CLT" }
    ]
};

export const orgTypesConstants = {
    FUNDER: 'Funder',
    RO_ORGANIZATION: 'Read Only Organization',
    OWNER: 'Owner',
    PROP_MGR: 'Property Manager',
    CONTRACTOR: 'Contractor',
    PREPARER: 'Preparer',
};

export const userRoleConstants = {
    organizationAdmin: 'Organization Admin',
    onsiteManager: 'Onsite Manager',
    none: 'None'
};

export const configurationConstants = {
    bedrooms:"Bedrooms",
    cities:"City",
    counties:"County",
    expenseCategory:"ExpenseCategory",
    homeUnitOccupancy:"HOMEUnitOccupancy",
    lenders:"Lender",
    mailTemplate:"MailTemplate",
    race:"Race",
    setAsideType:"SetAsideType",
    setAsideType_ho:"SetAsideType_HO",
    setAsideCategory:"SetAsideCategory",
    specialNeedOption:"SpecialNeedOption",
    state:"State",
    subsidyType:"SubsidyType",
    subsidyTypeType:"SubsidyTypeType",
    utilityAllowanceSource:"UtilityAllowanceSource",
    utilityAllowanceType:"UtilityAllowanceType",
    otherIncome:"OtherIncome",
    cityState:"",
    homeHouseHoldType:"HOMEHouseholdType",
    unitDesignation:"UnitDesignation",
    siteTypeForSorting:"SiteTypeForSorting",
    rentLimitIncomeLimitType:"RentLimitIncomeLimitType",
    HelpCategory:"HelpCategory",
    householdMemberInfoRace:"HouseholdMemberRace",
    householdMemberRace:"Race",
    bannerText:"BannerText",
    bedrooms_ho:"Bedrooms_HO",
    homeType:"HomeType",
    populationCategory:"PopulationCategory",
    typeHTFActivity:"TypeHTFActivity",
    populationCategoryHO:"PopulationCategoryHO",
    typeHTFActivityHO:"TypeHTFActivityHO",
    HOPriorResidenceStatus:"HOPriorResidenceStatus",
    sweatEquity:"SweatEquity",
    nonSweatEquity:"NonSweatEquity",
    MoveOutReason:"MoveOutReason",
    EvictionReason:"EvictionReason",
    NewHousingSituation:"NewHousingSituation",
    MoveOutReasonExpiryYear: "MoveOutReasonExpiryYear"
}

export const statusConstants = [
    {
        value: 0,
        label:"Inactive"
    },
    {
        value: 1,
        label:"Active"
    }

]

export const userStatusConstants = [
    { 
        label: 'Active', 
        id: 1 
    },
    { 
        label: 'Inactive', 
        id: 0 
    },
    {
        label: 'All', 
        id: 2
    }
]


export const FunderConstants = {
    arratype: [
        {
            value: true,
            name:"1602 Exchange"
        },
        {
            value: false,
            name:"TCAP"
        }
    ]
}

export const OccupantsConstants = {
    unitDesignation: [{
        value: 1,
        name:"Restricted"
    },
    {
        value: 2,
        name:"CAU-Manager"
    },
    {
        value: 3,
        name:"Market Rate"
    },
    {
        value: 4,
        name:"CAU-Maintenance"
    },
    {
        value: 5,
        name:"CAU-Security"
    }
    ],
    homeUnitOccupancies: [
        {
            value: 0,
            name:"None"
        },
        {
            value: 1,
            name:"Owner"
        },
        {
            value: 10,
            name:"Renter"
        },
        {
            value: 11,
            name:"Vacant"
        }
    ],
    homeHouseHoldTypes: [
        {
            value: 0,
            name:"None"
        },
        {
            value: 1,
            name:"Single - Non Elderly"
        },
        {
            value: 2,
            name:"Elderly"
        },
        {
            value: 3,
            name:"Related Single Parent"
        },
        {
            value: 4,
            name:"Related Parent"
        },
        {
            value: 5,
            name:"Other"
        },
        {
            value: 6,
            name:"Vacant Unit"
        }
    ],
    unitTypes: [
        {
            value: 0,
            name:"HOME"
        },
        {
            value: 1,
            name:"NHTF"
        }
    ],
    MoveOutReason: [{
        value: 1,
        name:"Can’t afford rent"
    },
    {
        value: 2,
        name:"Moving for job/family/school"
    },
    {
        value: 3,
        name:"Found a more desirable unit"
    },
    {
        value: 4,
        name:"Mutual termination"
    },
    {
        value: 5,
        name:"Being evicted"
    },
    {
        value: 6,
        name:"Abandoned unit"
    },
    {
        value: 7,
        name:"Passed away"
    },
    {
        value: 8,
        name:"Did not want to recertify"
    },
    {
        value: 9,
        name:"Buying a home"
    }, {
        value: 10,
        name:"Resident didn’t provide reason"
    }
    ],
    EvictionReason: [{
        value: 1,
        name:"Nonpayment of rent"
    },
    {
        value: 2,
        name:"Violation of lease (other than rent)"
    },
    {
        value: 3,
        name:"Violation of tax credit program rules"
    },
    {
        value: 4,
        name:"Criminal activity"
    }],
    NewHousingSituation: [{
        value: 1,
        name:"Another rental unit"
    },
    {
        value: 2,
        name:"Buying a home"
    },
    {
        value: 3,
        name:"Moving in with family/friends"
    },
    {
        value: 4,
        name:"Going to a shelter"
    },
    {
        value: 5,
        name:"Going to residence with higher level of care/services"
    },
    {
        value: 6,
        name:"Resident didn’t provide"
    }]
}

export const ReportCoverConstants = {
    certification: [
        {
            value:"None",
            label:"None"
        },
        {
            value:"Accepted",
            label:"Accepted"
        }
    ]
}

export const SitesConstants = {
    custom_report: [
        {
            label: 'Default',
            value:"Default"
        },
        {
            label: 'Alternate',
            value:"Alternate"
        },
        {
            label: 'Farmworker',
            value:"Farmworker"
        }
    ]
}

export const userconstants = {
    Readonly: [
        {
            label:"Read/Write",
            value: 0

        },
        {
            label:"Read Only",
            value: 1
        }
    ],

    ReadonlyUser: [
        {
            label:"Read Only",
            value: 1
        }
    ],

    Role: [
        {
            label:"Organization Admin",
            value:"Organization Admin"
        },
        {
            label:"Onsite Manager",
            value:"Onsite Manager"
        },
        {
            label:"None",
            value:""
        }
    ],

    RoleOA: [
        {
            label:"Organization Admin",
            value:"Organization Admin"
        },
        //For onsite Manager
        {
            label:"Onsite Manager",
            value:"Onsite Manager"
        },
        {
            label:"None",
            value:""
        }
    ],

    RoleOAHO: [
        {
            label:"Organization Admin",
            value:"Organization Admin"
        },
        //For onsite Manager
        // {
        //     label:"Onsite Manager",
        //     value:"Onsite Manager"
        // },
        {
            label:"None",
            value:""
        }
    ],

    roleAccess: [
        {
            value: true,
            label:"Yes"
        },
        {
            value: false,
            label:"No"
        }
    ]
}

export const ReportLevelsConstants = {
    0: 'Onsite Manager',
    1: 'Onsite Manager',
    2: 'Property Manager',
    3: 'Owner',
    4: 'Funder',
    'undefined': ''
}

export const rentPeriodDetails = {
    rentPeriodLimitName: [
        {
            value: 1,
            label: 'Day'
        },
        {
            value: 2,
            label: 'Week'
        },
        {
            value: 3,
            label: 'Month'
        }
    ]
}

export const relationshipHOH = [
    {
        value: 1,
        label: 'H - Head of Household'

    },
    {
        value: 2,
        label: 'S - Spouse'
    },
    {
        value: 3,
        label: 'A - Adult Co-Resident'
    },
    {
        value: 4,
        label: 'C - Child'
    },
    {
        value: 5,
        label: 'F - Foster Child(ren)/Adult'
    },
    {
        value: 6,
        label: 'L - Live-in Caretaker'
    },
    {
        value: 7,
        label: 'O - Other'
    }
];

export const discloseType = [
    {
        value: 1,
        label: '1 - Yes'
    },
    {
        value: 2,
        label: '2 - No'
    },
    {
        value: 3,
        label: '3 - Did Not Disclose'
    }
]

export const ethinicity = [
    {
        value: 1,
        label: '1 - Hispanic or Latino'
    },
    {
        value: 2,
        label: '2 - Not Hispanic or Latino'
    },
    {
        value: 3,
        label: '3 - Did Not Disclose'
    },
    {
        value: 9,
        label: '9 - Missing/Not Collected'
    }
];

export const typeOfAssets = [
    {
        value: 1,
        label: 'Checking'
    },
    {
        value: 2,
        label: 'Savings'
    },
    {
        value: 3,
        label: 'IRA/Retirement'
    },
    {
        value: 4,
        label: 'Money Market'
    },
    {
        value: 5,
        label: 'Pension'
    },
    {
        value: 6,
        label: 'Annuity'
    },
    {
        value: 7,
        label: 'Real Estate'
    },
    {
        value: 8,
        label: 'Trusts'
    },
    {
        value: 9,
        label: 'Other'
    }
];

export const incomeTypeCI = [
    {
        value: 1,
        label: 'C'
    },
    {
        value: 2,
        label: 'I'
    }
];

export const occupantsTabIndex = {
    1: 'householdinfo',
    3: 'monthlyrentdata'
}

export const householdStudentException = [
    {
        value: 1,
        label: '1 - TANF Assistance'
    },
    {
        value: 2,
        label: '2 - Job Training Program'
    },
    {
        value: 3,
        label: '3 - Single parent/dependent child'
    },
    {
        value: 4,
        label: '4 - Married/joint retun'
    },
    {
        value: 5,
        label: '5 - Previous Foster Care assistance'
    }
];

export const importStatus = {
    yet_to_start:"Yet to Start",
    inProgress:"In Progress",
    pending:"Pending",
    success:"Success",
    file_inprogress:"File Uploading Inprogress"
};

export const ComplianceData = {
    tablesCompliance: [
        {
            value: '1',
            label: 'Table 1'
        },
        {
            value: '2',
            label: 'Table 2'
        },
        {
            value: '3',
            label: 'Table 3'
        },
        {
            value: '4',
            label: 'Table 4'
        }
    ],
    conditionTypes: [
        {
            value: 'equal',
            label: '='
        },
        {
            value: 'greater',
            label: '>'
        },
        {
            value: 'lesser',
            label: '<'
        },
        {
            value: 'greaterequal',
            label: '>='
        },
        {
            value: 'lesserequal',
            label: '<='
        },
        {
            value: 'notequal',
            label: '!='
        }
    ],
    valueTypeData: [
        {
            value: 'percentage',
            label: 'Percentage'
        },
        {
            value: 'dollar',
            label: 'Dollar'
        },
        {
            value: 'number',
            label: 'Number'
        },
        {
            value: 'month',
            label: 'Month'
        },
        {
            value: 'year',
            label: 'Year'
        }
    ],
    warnings: [
        {
            value: 2,
            label: 'Warning'
        },
        {
            value: 3,
            label: 'Hard Stop'
        }

    ],
    siteTypes:[
        {
            value: 'All',
            label: 'All'
        },
        {
            value: 'Alternate',
            label: 'Alternate'
        },
        {
            value: 'Default',
            label: 'Default'
        },
        {
            value: 'Farmworker',
            label: 'Farmworker '
        }
    ]
};

export const elderlyConstants = [
    {
        value: 0,
        label:"None"
    },
    {
        value: 1,
        label:"55+"
    },
    {
        value: 2,
        label:"All 62+"
    },
    {
        value: 3,
        label:"HUD Elderly"
    },
    {
        value: 4,
        label:"RD 515"
    }
];

export const dashboardDownloadReportTypes = [
    {
        value: 0,
        label:"Funder Data Export"
    },
    {
        value: 1,
        label:"Project Summary Report"
    },
    {
        value: 2,
        label:"Table 1 Tenant Activity Report"
    },
    {
        value: 3,
        label:"Table 2 Report"
    },
    {
        value: 4,
        label:"Table 3 Report"
    },
    {
        value: 5,
        label:"Table 4 Report"
    },
]

export const dashboardHODownloadReportTypes = [
    {
        value: 0,
        label:"Homeownership Annual Report"
    },
    {
        value: 1,
        label:"Homeownership Submission Report"
    }
]

export const templateCategoryConstants = [
    {
        value:"user",
        label:"By Users"
    },
    {
        value:"project",
        label:"By Projects"
    }
];

export const templateUserCategoryConstants = [
    {
        value:"onsiteManager",
        label:"Onsite Manager"
    },
    {
        value:"propertyManager",
        label:"Property Manager"
    },
    {
        value:"owner",
        label:"Owner"
    }
];

export const activeStatusConstants = [
    {
        value: true,
        label:"Active"
    },
    {
        value: false,
        label:"Inactive"
    }
];

export const ExploreDataConditions = [
    {
        value: 'equal',
        label:"EQUAL"
    },
    {
        value: 'notequal',
        label:"NOT EQUAL"
    },
    {
        value: 'greater',
        label:"GREATER THAN"
    },
    {
        value: 'lesser',
        label:"LESS THAN"
    },
    {
        value: 'greaterequal',
        label:"GREATER THAN OR EQUAL"
    },
    {
        value: 'lesserequal',
        label:"LESS THAN OR EQUAL"
    },
    {
        value: 'contains',
        label:"CONTAINS"
    },
    {
        value: 'sum',
        label:"SUM"
    },
    {
        value: 'average',
        label:"AVERAGE"
    },
    {
        value: 'percentage',
        label:"PERCENTAGE"
    },
    {
        value: 'count',
        label:"COUNT"
    }
];

export const validationHelpText = {
   "Reported Developmentally Disabled set aside is less than required.": "Developmentally Disabled set aside should be equal to or greater than the values configured in funder setting page.",
   "Reported Frail Elderly set aside is less than required.": "Frail Elderly set aside should be equal to or greater than the values configured in funder setting page.",
   "Reported Elderly set aside is less than required.": "Elderly set aside should be equal to or greater than the values configured in funder setting page.",
   "Reported People Living with HIV/AIDS set aside is less than required.": "People Living with HIV/AIDS set aside should be equal to or greater than the values configured in funder setting page.",
   "Reported Substance Abusers / In Recovery set aside is less than required.": "Substance Abusers / In Recovery set aside should be equal to or greater than the values configured in funder setting page.",
   "Reported Veterans set aside is less than required.": "Veterans set aside should be equal to or greater than the values configured in funder setting page.",
   "Reported Physically Challenged set aside is less than required.": "Physically Challenged set aside should be equal to or greater than the values configured in funder setting page.",
   "Reported Traumatic Brain Injury set aside is less than required.": "Traumatic Brain Injury set aside should be equal to or greater than the values configured in funder setting page.",
   "Reported At Risk Homelessness set aside is less than required.": "At Risk Homelessness set aside should be equal to or greater than the values configured in funder setting page.",
   "Reported Mentally Ill / Chemically Addicted set aside is less than required.": "Mentally Ill / Chemically Addicted set aside should be equal to or greater than the values configured in funder setting page.",
   "Reported Multiple Special Needs set aside is less than required.": "Multiple Special Needs set aside should be equal to or greater than the values configured in funder setting page.",
   "Reported Farmworker set aside is less than required.": "Farmworker set aside should be equal to or greater than the values configured in funder setting page.",
   "Reported Farmworker - Seasonal set aside is less than required.": "Farmworker - Seasonal set aside should be equal to or greater than the values configured in funder setting page.",
   "Reported Families with Children set aside is less than required.": "Families with Children set aside should be equal to or greater than the values configured in funder setting page.",
   "Reported Youth <18 set aside is less than required.": "Youth <18 set aside should be equal to or greater than the values configured in funder setting page.",
   "A Subsidy Type was selected; a Subsidy Amount must be entered.": "When any values are chosen in Primary Rent Subsidy Type drop-down, then the value must be entered in the Total Subsidy Amount field in Set Aside & Rent Data tab",
   "Must have Move In Income even if it is $0.":"Move-in Annual Gross Income field cannot be blank, it should have a value atleast 0 or greater than 0 ",
   "Household Size required.":"Household Size cannot be blank, it should have a value greater than 0",
   "Unit has been vacant or offline more than 90 days.":"Unit is vacant & move-out date is more than 90 days",
   "Difference between Certification Dates must be less than 1 year.":"Difference Between Current Certification Date & Previous Certification Date must not be greater than 12 months",
   "The Resident Payment + Utility is more than the maximum allowable Rent.":"Value in Total Resident Payment is greater than Maximum Allowable Rent field in the Set Aside & Rent Data tab",
   "The unit must have a current certification date":"When Unit designation is Restricted, the Current Certification Date cannot be blank it should have any Current Reporting Date",
   "Annual Income exceeds maximum TC/HUD allowed income by 140%":"When the value entered in the Current Annual Gross Income is greater than 140% of the Current Maximum Allowable Income assigned in Income Limit pool",
   "Recert dates must be within the entered move-in and move-out dates.":"Current Certification Date must be entered between Move-In date and Move Out Date",
   "Total Annual Household Income - All Sources' should equal 'Current Annual Gross Income'":"Values in Total Annual Household Income - All Sources & Current Annual Gross Income should be the same",
   "The total number of HOME units configured is less than that required by Funders.":"The Home count set by funders in the funder setting page should equal with Table 1 'This is a HOME Program Unit'",
   "HOME program requirements":"The Home count set by funders in the funder setting page should equal with Table 1 'This is a HOME Program Unit'",
   "This unit is too small to qualify household for Large Household Special Needs set-aside – unit must be at least 3 bdrms or larger.":"When Tax Credits Special Needs set as Large Household & bedroom value should be 3 or greater",
   "This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.":"When Tax Credits Special Needs set as Large Household & Move in household Size should be 4 or greater",
   "Move In Income is equal to or more than the maximum TC/HUD allowed income.":"Value entered in Move in Annual Gross Income is greater than or equal to value in Move in maximum Allowable Income",
   "The units current certification date is more than 12 months old.":"The Current Certification date should not be more than 12 months old with the current reporting year ",
   "Annual Income exceeds HOME maximum allowable income.":"Unit is set as Home & Restricted Unit Percentage value selected as 50% or below & Actual % of Median Area Income value is greater than 50",
   "Annual Income exceeds NHTF maximum allowable income.":"Unit is set as NHTF & Restricted Unit Percentage Value selected as 50% or below & Actual % of Median Area Income Value is greater than 50",
   "RUP chosen is higher than max income set-aside required by funder(s)":"Restricted Unit Percentage/Move in Restricted Unit Percentage is greater than the LIH Percentage set by monitoring funders in the funder setting page ",
   "Race and Ethnicity Total for Households Served does not equal Total Number of Households Served.":"Count in Table 2 Number of Households Served should be equal to the total count entered in Race and Ethnicity of Restricted Unit Household Served",
   "Number of Minors is greater than the Total Households Served in Section 5":"The Number of Minors (if known) is greater than the Number of Households Served(Section 5) in Table 2",
   "Total Household Income Households Served does not equal Total Households Served in Section 5.":"Value entered in Household Income should be equal to Number of Households Served",
   "Total Number of occupants is greater than the Total Households Served in Section 5":"Value entered in Table 2 Total Number of occupants(Section 8) is greater than Table 2 Number of Households Served(Section 5)",
   "Total Occupied Bednights (used) is greater than the Total Bednights Available":"The Count entered in Total Occupied Bednights (used) is greater than the value in Total Bednights Available in Dates of Operation and Bednights",
   "Section 7 - The total number of 'Households Served During the Year' entered doesn't match the system calculated total. The system pulls the total from Table 1 household records.":"Total Value entered in Table 2 Number of Restricted Units or Beds must be equal to the Total count entered in Total Restricted Unit Households Served During the Year ",
   "Section 6 - Single Parent Head of Household total can't be greater than the total number of restricted households served during the year.":"Value entered in Table 2 Total Restricted Unit Households Served (Section6) is greater than Number of Restricted Units Households Served (Section 3)",
   "Total Single Parent Households Served does not equal Total Number of Households Served":"Value entered in Single Parent Head of Household (Section 6) should be equal to Number of Households Served (Section 3)",
   //"Section 1 - The total number of 'Restricted Units' entered doesn't match the system calculated total. The system pulls the total from Table 1 household records.":"The Sum of Value entered in Table 2 Restricted Renter Units & Restricted Owner Units must be equal to Total Number of Restricted Units as of 12/31 ",
   "Section 1 - The total number":"The Sum of Value entered in Table 2 Restricted Renter Units & Restricted Owner Units must be equal to Total Number of Restricted Units as of 12/31 ",
   "Section 4 - Head of Household by Race and Ethnicity total doesn't match the total number of households served during the year.":"Total Value entered in Table 2 Number of Restricted Units or Beds must be equal to the Total count entered in Race and Ethnicity of Restricted Unit Household Served  ",
   "Section 2 - Homeless Household total can't be greater than the total number of restricted households served during the year.":"Value entered in Total Number of Homeless Households in Restricted Units Table 3 (Section 2) is greater than the Total number of household served in Table 2",
   "Section 1 - Special Needs Head of Household total can't be greater than the total number of restricted households served during the year.":"Value entered in Restricted Unit Special-Needs Population T3 (Section 1) is greater than the Total number of household served in Table 2",
   "Section 1 - The Head of Household count for a Special Needs population doesn't meet the minimum required for at least one monitoring funder.":"Count entered in Restricted Unit Special-Needs Population must satisfy at least one monitoring funder's Special Need configuration in the funder setting page ",
   "Industry Best Practice recommends having Reserves; currently the Reserves are <= $0.":"Values in Reserves Balance must be greater than 1",
   "Table 4A Reserves: Starting balance does not match last year's ending balance.":"The Amount entered in Replacement Reserves Beginning Balance is not equal to Replacement Reserve Last Year Ending Balance in Table 4A",
   "Table 4B Reserves:  Starting balance does not match last year's ending balance.":"The Amount entered in Operating Reserves Beginning Balance is not equal to Operating Reserve Last Year Ending Balance in Table 4B",
   "Total Number of Special Need Population is greater than Total Household Served on Table 2":"Value entered in Restricted Unit Special-Needs Population T3 (Section 1) is greater than the Total number of household served in Table 2",
   "Current Certification Date must be entered between Move In date and Move Out Date":"The entered Current Certification Date must be entered between Move In date and Move Out Date",
   "Total Number of Homeless Households is greater than Total Households Served on Table 2":"Value entered in Total Number of Homeless Households in Restricted Units Table 3 (Section 2) is greater than the Total number of household served in Table 2",
   "The Total Households Served in Section 4 should be the same as the Total Households Served in Section 3.":"The value entered in total household served should be equla to total household served",
   "Total household served in section 5 should be the same as the total household served in section 3":"The value entered in total household served should be equal to total household served",
   "Total Single Parent households in Section 6 should be lesser than the Total Household Served count in Section 3":"The value entered in total single parent household is lesser than the total household served count",
   "A valid value greater than 0 for the Temporary Farmworker Housing (# of Beds) is required":"Value entered in Table 2 for the Temporary Farmworker Housing (# of Beds)  must be greater than  '0'",
   "The beginning report year date of operation must be in the reporting year of 2023":"The report year dates of operation begin date should have any current report year date.",
   "The ending report year date of operation must be in the reporting year of 2023":"The report year dates of operation end date should have any current report year date.",
   "You must enter one valid value for Rent per Household (per unit)":"Values for Rent per Household (per unit)  must be greater than 0",
   "Total Household served is should be greater than 0":"Values in Household served is should be greater than 0",
   "The Total Households Served in Section 5 should be the same as the Total Households Served in Section 6.":"Values entered in Total households served in (section5) should be equal to Number of Households Served (Section 6)",
   "Total Households served in Section 7 should be same as the Total Household Served count in Section 5":"Values entered in total household served in section 7 should be equal to the total household served count in section 5",
   "You must enter one valid value for Rent per Individual (per bed)":"Rent per Individual (per bed) cannot be empty",
   "Either Single Bed Units or Household Units must be greater than 0":"Enter the value greater than 0 for either Single Bed Units or Household Units",
   "The beginning report year date of operation":"The report year dates of operation begin date should have any current report year date",
   "The ending report year date of operation":"The report year dates of operation end date should have any current report year date",
   "The Total Occupied Bed nights (used) can't be greater than the Total Bed Nights Available":"Total Occupied Bed nights should not be greater than Total Bed Nights Available",
   "Total Occupied Bednights (used) is greater than the Total Bednights Available":"Total Occupied Bed nights should not be greater than Total Bed Nights Available",
   "Reported Young Adult 18-24 set aside is less than required.": "Young Adult 18-24 should be equal to or greater than the values configured in funder setting page.",
   "Reported General set aside is less than required.": "General should be equal to or greater than the values configured in funder setting page.",
   "Reported Chronic Mental Illness set aside is less than required.": "Chronic Mental Illness should be equal to or greater than the values configured in funder setting page.",
   "Reported Domestic Violence Survivors set aside is less than required.": "Domestic Violence Survivors should be equal to or greater than the values configured in funder setting page."
}

export const validationHelpTextHO = {
	"section1-numberofloansmustmatchthenumberlistedinprogramactivity" : "Number of Loans generated for the reporting period should have value atleast 0 or greater than 0, the number of loans entered in the Number of Loans generated for the reporting period must be given in the Please identify the record number(s) of loans generated for reporting period.",
	"section1-numberofloansgeneratedforthereportingperiodinprogramactivitynotequaltorecordnumberwithloan?checked'yes'" : "Number of Loans generated for the reporting period should have value atleast 0 or greater than 0, the number of loans entered in the Number of Loans generated for the reporting period must be equal to the Record number with Loan checked 'Yes'.",
	"section1-recordnumberdoesnotmatcharecordincontractactivity" : "Record number in Please identify the record number(s) of loans generated for reporting period in Program activity must be entered in Record numbers in Contract activities with Loan? checked 'Yes'",
	"section1-recordnumberisonethatisconsideredclosedviaforeclosureorhasbeensatisfied" : "Record number in Please identify the record number(s) of loans generated for reporting period in Program activity must not be entered in Record numbers with loan checked 'Yes' in contract activities which is previous Was Property Forclosure?/Is the HTF Loan Satisfied? closed which is checked as Yes",
	"section2-numberofgrantsmustmatchthenumberlistedinprogramactivity" : "Number of grants  generated for the reporting period should have value atleast 0 or greater than 0, the number of grants entered in the Number of grants generated for the reporting period must be given in the Please identify the record number(s) of the grants generated for the reporting period.",
	"section2-recordnumberisnotlistedasagrantincontractactivitydetail" : "Number of Grants generated for the reporting period should have value atleast 0 or greater than 0, the number of Grants entered in the Number of Grants generated for the reporting period must be equal to the Record number with Grant checked 'Yes'.",
	"section2-recordnumberdoesnotmatcharecordincontractactivity" : "Record number in Please identify the record number(s) of the grants generated for the reporting period in Program activity must be entered  in Record numbers in contract activities with Grant? Checked 'Yes'.",
	"section2-recordnumberisonethatisconsideredclosedviaforeclosureorhasbeensatisfied" : "Record number in Please identify the record number(s) of the grants generated for the reporting period in Program activity must not be entered in Record numbers in contract activities with Grant? Checked 'Yes' which is previous Was Property Forclosure?/Is the HTF Loan Satisfied? closed which is checked as Yes.",
	"section3-numberofloansmustmatchthenumberlistedinprogramactivity" : "Number of resales  in the reporting period should have value atleast 0 or greater than 0, the number of records entered in the Number of resales in the reporting period must be given in the Please identify the record number(s) of resales in the reporting period and the record number in the contract activity.",
	"section3-numberofresales" : "Number of resales in the reporting period in Program activity are less than the Record numbers in contract activity with Type of HTF Activity listed as 'Resale Activity'",
	"section3-recordnumberdoesnotmatcharecordincontractactivity" : "Record number in Please identify the record number(s) of resales in the reporting period in Program activity must be entered  in Record numbers in Contract activity.",
	"section4-recordnumberdoesnotmatcharecordincontractactivity" : "Record number in Please identify the record number(s) of the loans deferred for the reporting period in Program Activity must be entered in Record numbers in Contract activities.",
	"section4-recordnumberisonethatisconsideredclosedviaforeclosureorhasbeensatisfied" : "Record number in Please identify the record number(s) of the loans deferred for the reporting period, in Program activity must not be entered in Record numbers in contract activities which is previous Was Property Forclosure?/Is the HTF Loan Satisfied? closed which is checked as Yes.",
	"section5-recordnumberdoesnotmatcharecordincontractactivity" : "Record number in Please identify the record number(s) of the loans in default for the reporting period, in Program activity must be entered in Record numbers in contract activities.",
	"section5-recordnumberisonethatisconsideredclosedviaforeclosureorhasbeensatisfied" : "Record number in Please identify the record number(s) of the loans in default for the reporting period, in Program activity must not be entered similar in Record numbers which is previous Was Property Forclosure?/Is the HTF Loan Satisfied? closed which is checked as Yes.",
	"section6-numberofloansmustmatchthenumberlistedinprogramactivity" : "Number of Foreclosures loan generated for the reporting period should have value atleast 0 or greater than 0, the number of deferred loans entered in the Number of  Loans deferred for the reporting period must be given in the Please identify the record number(s) of the loans that foreclosed for the reporting period.",
	"section6-recordnumberdoesnotmatcharecordincontractactivity" : "Record number in Please identify the record number(s) of the loans that foreclosed for the reporting period in Program activity must be entered in Record numbers in contract activities.",
	"section6-recordnumberisonethatdoesnotmatcharecordwiththeforeclosureboxchecked'yes'" : "Record number in Please identify the record number(s) of the loans that foreclosed for the reporting period in Program activity must not be entered similar in Record numbers in contract activities which is previous Was Property Forclosure? checked as Yes",
	"section4a-numberofloansmustmatchthenumberlistedinfinancialactivity" : "Number of Loans with payment for the reporting period should have value atleast 0 or greater than 0, the number of loans with payment entered in the Number of Loans with payments in the reporting  period must be given in the Please identify the record number(s) of the loans with payments in the reporting period in Financial activity with loan or grant checked 'Yes'.",
	"section4a-recordnumberdoesnotmatcharecordincontractactivity" : "Record number in Please identify the record number(s) of the loans with payments in the reporting period,in Financial activity must be entered in Record numbers in contract activities.",
	"section4a-recordnumberisonethatisconsideredclosedviaforeclosureorhasbeensatisfied" : "Record number in Please identify the record number(s) of the loans with payments in the reporting period, in financial activity must not be entered in Record numbers in contract activities which is previous Was Property Forclosure?/Is the HTF Loan Satisfied? closed which is checked as Yes",
	"section4b-numberofloansmustmatchthenumberlistedinfinancialactivity" : "Number of Loans Satisfied for the reporting period should have value atleast 0 or greater than 0, the number of loans with payment entered in the Number of Loans with payments in the reporting  period must be given in the Please identify the record number(s) of the loans satisfied in the reporting period in Financial activity.",
	"section4b-recordnumberdoesnotmatcharecordincontractactivity" : "Record number in Please identify the record number(s) of the loans satisfied in the reporting period in Financial activity must be entered in Record numbers in contract activities.",
	"section4b-recordnumberisonethatisnotmarkedassatisfiedinrecorddetail" : "Record number in Please identify the record number(s) of the loans satisfied in the reporting period  in financial activity must not be entered in Record numbers in contract activities which is previousHTF Loan Satisfied? closed which is checked as No.",
	"section5a-recordnumberdoesnotmatcharecordincontractactivity" : "Record number in Please identify the record number(s) of the loans that incurred bad debt in the reporting period must be entered in Record numbers in contract activities."
}

export const arithmeticOperators = ["sum","percentage","average","count"];

export const rentBurderFilters = ["State","County","City","Zipcode","Funder","Ownership Organization","Property Management Organization"];

export const prrFilters = ["County","City","Zipcode","Ownership Organization","Property Management Organization"];

export const fileFormatConstants = [{label:"XML", value: 0},{label:"JSON", value:1}];