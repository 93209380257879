import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Switch from '@material-ui/core/Switch';

//Style
import pageStyle from '../../assets/jss/containers/common';
import { formatDate } from '../../helpers';

//Components
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import EditableTable from '../../components/EditableTable/EditableTable';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import TextBox from '../../components/TextBox/TextBox';
import TextArea from '../../components/TextArea/TextArea';
import SaveCancel from '../../components/SaveCancel/SaveCancel';
import HelpFloater from '../../components/HelpFloater/HelpFloater';

//Actions
import { complianceActions } from '../../actions/compliance';
import { alertActions } from '../../actions/alert';
import { helpActions } from '../../actions';

//Helper
import { history } from '../../helpers';
import { ComplianceData } from '../../helpers/appConstants';

/**
 * Initial Data
 */
const ComplianceSettingsData = {
    systemId : '',
    fieldA: {
        title: '',
        collection: '',
        field: ''
    },
    condition: '',
    fieldB: {
        title: '',
        collection: '',
        field: ''
    },
    value: '',
    valueType: '',
    type: '',
    message: '',
    table: '',
    siteType:'',
    displayingTable:''
};

/**
 * FieldA component
 * @class FieldA
 * @extends {Component}
 * @memberof EditableTable
 */
class FieldA extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config } = this.props;
        const option = config[`table${data.table}`] || [];
        let fieldValues = option.map(fr => {
            fr.label = fr.title;
            fr.value = fr.field;
            return fr;
        });
        let systemIds = [
            "CV-11",
            "CV-16",
            "CV-18",
            "CV-19",
            "CV-21",
            "CV-22",
            "CV-23",
            "CV-24",
            "CV-25",
            "CV-26",
            "CV-27",
            "CV-28",
            "CV-29",
            "CV-33"
          ];
   
        if (systemIds.includes(data['systemId'])) {
             if (data[fieldName]) {
                let {title, field}  = data[fieldName];
                fieldValues.push({
                    label: title,
                    value: field
                })
            }
           
        }
        return (
            <AutoComplete
                suggestions={fieldValues}
                name={`${fieldName}_${rowIndex}`}
                handleAutoCompolete={(selectedOption) => {
                    onChange(fieldName, rowIndex, selectedOption.value);
                }}
                selectedValue={{ id: data[fieldName].field || '' }}
                className={'autocomplete-fixed'}
                validators={data.createdBy === 'system' ? [] : ['required']}
                errorMessages={['this field is required']}
                disabled={data.createdBy === 'system'}
            />
        )
    }
};
/**
 * EditableText component
 * @class EditableText
 * @extends {Component}
 * @memberof EditableTable
 */
class EditableText extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;
        return (
            <TextBox
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
                disabled={data.createdBy === 'system'}
                type={'Number'}
                className={'textspin'}
            />
        )
    }
};
/**
 * EditableTextMessage component
 * @class EditableTextMessage
 * @extends {Component}
 * @memberof EditableTable
 */
class EditableTextMessage extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;
        return (
            <TextArea
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                className="w-max-c adjust-height"
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
                maxLength={'500'}
                validators={data.createdBy === 'system' ? [] : ['required']}
                errorMessages={['this field is required']}
                multiline={true}
            // disabled={data.createdBy == 'system'}
            />
        )
    }
};
/**
 * Condition component
 * @class Condition
 * @extends {Component}
 * @memberof EditableTable
 */
class Condition extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { ComplianceData } } = this.props;
        return (
            <AutoComplete
                suggestions={ComplianceData.conditionTypes}
                name={`${fieldName}_${rowIndex}`}
                handleAutoCompolete={(selectedOption) => {
                    onChange(fieldName, rowIndex, selectedOption.value);
                }}
                selectedValue={{ id: data[fieldName] || '' }}
                // className={'textBox--bordered'}
                validators={data.createdBy === 'system' ? [] : ['required']}
                errorMessages={['this field is required']}
                disabled={data.createdBy === 'system'}
            />
        )
    }
};
/**
 * Table component
 * @class Table
 * @extends {Component}
 * @memberof EditableTable
 */
class Table extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { ComplianceData } } = this.props;
        return (
            <div style={{width:'75px'}}>
            <AutoComplete
                suggestions={ComplianceData.tablesCompliance}
                name={`${fieldName}_${rowIndex}`}
                handleAutoCompolete={(selectedOption) => {
                    onChange(fieldName, rowIndex, selectedOption.value);
                }}
                selectedValue={{ id: data[fieldName] || '' }}
                // className={'textBox--bordered'}
                validators={data.createdBy === 'system' ? [] : ['required']}
                errorMessages={['this field is required']}
                disabled={data.createdBy === 'system'}
            />
            </div>
        )
    }
};
/**
 * Table component
 * @class Table
 * @extends {Component}
 * @memberof EditableTable
 */
class DisplayingTable extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { ComplianceData } } = this.props;
        return (
            <AutoComplete
                suggestions={ComplianceData.tablesCompliance}
                name='displayingTable'
                handleAutoCompolete={(selectedOption) => {
                    onChange(fieldName, rowIndex, selectedOption.value);
                }}
                selectedValue={{ id: data[fieldName] || '' }}
                // className={'textBox--bordered'}
                // validators={data.createdBy === 'system' ? [] : ['required']}
                // errorMessages={[]}
                disabled={true}
            />
        )
    }
};
/**
 * SiteType component
 * @class SiteType
 * @extends {Component}
 * @memberof EditableTable
 */
class SiteType extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { ComplianceData } } = this.props;
        return (
            <AutoComplete
                suggestions={ComplianceData.siteTypes}
                name={`${fieldName}_${rowIndex}`}
                handleAutoCompolete={(selectedOption) => {
                    onChange(fieldName, rowIndex, selectedOption.value);
                }}
                selectedValue={{ id: data[fieldName] || '' }}
                // className={'textBox--bordered'}
                // validators={data.createdBy === 'system' ? [] : ['required']}
                // errorMessages={['this field is required']}
                disabled={data.createdBy === 'system'}
            />
        )
    }
};
/**
 * ValueType component
 * @class ValueType
 * @extends {Component}
 * @memberof EditableTable
 */
class ValueType extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { ComplianceData } } = this.props;
        return (
            <AutoComplete
                suggestions={ComplianceData.valueTypeData}
                name={`${fieldName}_${rowIndex}`}
                handleAutoCompolete={(selectedOption) => {
                    onChange(fieldName, rowIndex, selectedOption.value);
                }}
                selectedValue={{ id: data[fieldName] || 's' }}
                // className={'textBox--bordered'}
                validators={data.createdBy === 'system' ? [] : ['required']}
                errorMessages={['this field is required']}
                disabled={data.createdBy === 'system'}
            />
        )
    }
};
/**
 * FieldB component
 * @class FieldB
 * @extends {Component}
 * @memberof EditableTable
 */
class FieldB extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config } = this.props;
        const option = config[`table${data.table}`] || [];
        let fieldValue = option.map(fr => {
            fr.label = fr.title;
            fr.value = fr.field;
            return fr;
        });
        let systemIds =[
            "CV-16",
            "CV-18",
            "CV-19",
            "CV-24",
            "CV-25",
            "CV-26",
            "CV-27",
            "CV-28",
            "CV-10",
            "CV-12",
            "CV-17",
            "CV-20"
          ];
        
        if (systemIds.includes(data['systemId'])) {
            if (data[fieldName]) {
                let {title, field}  =  data[fieldName];
                fieldValue.push({
                    label: title,
                    value: field
                })
            }
           
        }
        return (
            <AutoComplete
                suggestions={fieldValue}
                name={`${fieldName}_${rowIndex}`}
                handleAutoCompolete={(selectedOption) => {
                    onChange(fieldName, rowIndex, selectedOption.value);
                }}
                selectedValue={{ id: data[fieldName].field || '' }}
                className={'autocomplete-fixed'}
                // validators={['required']}
                // errorMessages={['this field is required']}
                disabled={data.createdBy === 'system'}
            />
        )
    }
};
/**
 * Warning component
 * @class Warning
 * @extends {Component}
 * @memberof EditableTable
 */
class Warning extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { ComplianceData } } = this.props;
        return (
            <div className="autocompleteList">
                <AutoComplete
                    suggestions={ComplianceData.warnings}
                    name={`${fieldName}_${rowIndex}`}
                    handleAutoCompolete={(selectedOption) => {
                        onChange(fieldName, rowIndex, selectedOption.value);
                    }}
                    selectedValue={{ id: data[fieldName] || '' }}
                    // className={'textBox--bordered'}
                    validators={data.createdBy === 'system' ? [] : ['required']}
                    errorMessages={['this field is required']}
                // disabled={data.createdBy === 'system'}
                />
            </div>
        )
    }
};
/**
 * Status component
 * @class StatusComponent
 * @extends {Component}
 * @memberof EditableTable
 */
class StatusComponent extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;
        return (<Switch
            color="primary"
            size="small"
            checked={data[fieldName]}
            onChange={event => {
                onChange(fieldName, rowIndex, event.target.checked);
            }}
        // disabled={data.createdBy == 'system'}
        />);
    }
}

// default props
StatusComponent.defaultProps = {
    checked: false,
    disabled: true,
    onChange: () => { },
    data: {}
}

// component map
const componentMap = {
    fieldA: FieldA,
    condition: Condition,
    fieldB: FieldB,
    value: EditableText,
    type: Warning,
    message: EditableTextMessage,
    status: StatusComponent,
    table: Table,
    siteType:SiteType,
    displayingTable:DisplayingTable,
    valueType: ValueType
}

/**
 *Compliance Settings
 * @class ComplianceSetting
 * @extends {Component}
 */
class ComplianceSetting extends Component {
    /**
     *Creates an instance of ComplianceSetting.
     * @param {*} props
     * @memberof ComplianceSetting
     */
    constructor(props) {
        super(props);
        this.state = {
            ComplianceSetting: [{ ...ComplianceSettingsData }],
            updated: false,
            openHelp: false,
        }
    }
    /**
     * Component Will Mount
     */
    UNSAFE_componentWillMount = () => {
        this.props.getFields();
        this.props.getFieldData();
        this.props.getHelp();
    }
    /**
     * Component Will Recieve Props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const { complianceFieldData } = nextProps;
        if(this.props !==nextProps){
        //if (complianceFieldData.length > 0 && this.state.updated === false) {
            this.setState({
                ComplianceSetting: complianceFieldData,
                updated: true
            });
       // }
    }
    }
    /**
     * Handle Submit
     */
    handleSubmit = () => {
        this.saveForm();
    };
    /**
     * Save Form 
     */
    saveForm = () => {
        const formData = this.state.ComplianceSetting
        let alert_flag = false;
        formData.forEach((item) => {
            switch (item.createdBy) {
                case 'system':
                    // alert_flag = false;
                    return true;
                default:
                    if (item.value === '' && item.fieldB.title === '') {
                        this.props.showErrorAlert('Please enter FieldB or Value');
                        alert_flag = true;
                        return true;
                    }
            }
        });
        if (alert_flag === false) {
            this.props.updateData(formData,() => {
                this.props.getFieldData();
            });
        }
    }
    /**
     * Handle Add On Click
     */
    handleAddActionClick = () => {
        this.setState({
            ComplianceSetting: [
                {
                    ...ComplianceSettingsData,
                },
                ...this.state.ComplianceSetting
            ]
        });
    }
    /**
     * Handle Back On Click
     */
    handleBackActionClick = () => {
        history.goBack();
    }
    /**
     * Cell component change
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     */
    cellComponentOnChange = (fieldName, rowIndex, value) => {
        const { ComplianceSetting } = this.state;
        const { complianceField } = this.props;
        const data = ComplianceSetting[rowIndex].table;
        const formData = this.state.ComplianceSetting;
        value = value === undefined ? null : value;
        switch (fieldName) {
            case 'status':
                if (fieldName === 'fieldA' || fieldName === 'fieldB') {
                    ComplianceSetting[rowIndex][fieldName] = complianceField[`table${data}`].filter((item, i) => item.field === value)[0] || {};
                } else {
                    ComplianceSetting[rowIndex][fieldName] = value
                }
                this.setState({ ComplianceSetting });
                this.props.updateActiveStatus(formData[rowIndex]);
                break;

            default:
                if (fieldName === 'fieldA' || fieldName === 'fieldB') {
                    ComplianceSetting[rowIndex][fieldName] = complianceField[`table${data}`].filter((item, i) => item.field === value)[0] || {};
                } else {
                    ComplianceSetting[rowIndex][fieldName] = value
                }
                if(fieldName === 'table'){
                    ComplianceSetting[0].displayingTable = ComplianceSetting[0].table
                }
                this.setState({ ComplianceSetting });
        }
    }
    /**
     * Handle Help Click Action
     */
    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }
     /**
     * Handle Download Action Click
     */
     handleDownloadActionClick = () => {
        const {complianceFieldData} = this.props
        this.props.downloadComplianceError(complianceFieldData);
    }

    /**
     * Render Html
     */
    render() {
        let { pageAccess, classes, title, isSubPage,location: { pathname }, complianceField,complianceFieldData, helpData, modifiedBy,lastUpdatedAt} = this.props;
        const pageModifiedTitle = modifiedBy &&lastUpdatedAt ? `Last modified by: <b>${modifiedBy}</b> on <b>${formatDate(lastUpdatedAt)}</b>`:'Last modified by system on 01/01/2024'
        const { ComplianceSetting, openHelp } = this.state;
        pageAccess = [...pageAccess,'DOWNLOAD','HELP']
        return (
            <div className='complianceSettings'>

                <ContentWrapper
                    classes={classes}
                    pageAccess={pageAccess}
                    title={title}
                    subTitle={pageModifiedTitle}
                    needPadding={!isSubPage} 
                    isSubPage={isSubPage}
                    handleAddActionClick={this.handleAddActionClick}
                    handleDownloadActionClick={this.handleDownloadActionClick}
                    handleBackActionClick={this.handleBackActionClick}
                    handleHelpActionClick={this.handleHelpActionClick}
                >
                    <ValidatorForm
                        name="ComplianceSettings"
                        ref="form"
                        autoComplete="off"
                        onSubmit={this.handleSubmit}
                        onError={errors => { }}
                    >
                        <Grid item xs={12} sm={12} style={{ overflow: "auto", height: 'calc(100vh - 205px)' }}>
                            <EditableTable
                                headerCols={["ID","Table", "FieldA", "Condition", "FieldB", "Value", "ValueType", "Type","SiteType","Message","Display Message in Table", "Status"]}
                                fieldCols={["systemId","table", "fieldA", "condition", "fieldB", "value", "valueType", "type","siteType", "message","displayingTable", "status"]}
                                rowDatas={ComplianceSetting}
                                componentMap={componentMap}
                                page ={"compilancesettings"}
                                needTitle={false}
                                cellComponentOnChange={this.cellComponentOnChange}
                                config={{ ...complianceField, ComplianceData }}
                            />
                        </Grid>
                        <SaveCancel
                            handleSaveButtonClick={() => { this.refs.form.submit() }}
                            handleCancelButtonClick={() => { history.goBack() }}
                        />
                    </ValidatorForm>
                    {openHelp && <HelpFloater
                        handleCloseFloater={() => this.setState({ openHelp: false })}
                        {...helpData}
                        title="Compliances"
                    />}
                </ContentWrapper>
            </div>
        );
    }
}
/**
 * Set Props Types
 */
ComplianceSetting.propTypes = {
    classes: PropTypes.object.isRequired,
    removeActions: PropTypes.array,
    isSubPage: PropTypes.bool,
    pageAccess: PropTypes.array
};
/**
 * Default Props
 */
ComplianceSetting.defaultProps = {
    title: "GLOBAL COMPLIANCE SETTINGS",
    isSubPage: false,
    pageAccess: [],
    data: {},
    complianceField: []
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ compliance, pageAccess, help , search }, ownProps) => {
    const helpData = help.data[0]
    const modifiedBy = compliance?.fields[0]?.modifiedBy || ""
    const lastUpdatedAt = compliance?.fields[0]?.updatedAt || ""
    return {
        pageAccess: ownProps.pageAccess ? ownProps.pageAccess : pageAccess['compliancesetting'] || [],
        complianceField: compliance.data,
        complianceFieldData: compliance.fields,
        compliance : compliance,
        searchData: search['compliance'] || { sFields: [], sValues: [] },
        helpData,
        modifiedBy,
        lastUpdatedAt
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        updateData: (formData,callback) => dispatch(complianceActions.updateData(formData,callback)),
        updateActiveStatus: (formData) => dispatch(complianceActions.updateActiveStatus(formData)),
        getFields: () => dispatch(complianceActions.getFields()),
        getFieldData: () => dispatch(complianceActions.getFieldData()),
        showErrorAlert: (error) => dispatch(alertActions.error(error)),
        getHelp: () => dispatch(helpActions.getAll({ _id: 13 })),
        downloadComplianceError: (complianceData) => dispatch(complianceActions.downloadComplianceError(complianceData, 'WBARS_Compliance.xlsx')),
    }
};
// export ComplianceSetting
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(ComplianceSetting));
